import { findAll, getWithFieldQuery } from './firebase';

export const getCategories = () => {
  return findAll('categories');
};

/**
 *
 * @param {string} categoryID
 * @returns question object | null (no question to return for this category)
 */
export const getRadnomCategoryQuestion = async (categoryID) => {
  const categoryQuestions = await getWithFieldQuery(
    'questions',
    'category',
    '==',
    categoryID
  );

  //   TODO check if empty , get random element
  if (
    !categoryQuestions ||
    !Array.isArray(categoryQuestions) ||
    categoryQuestions.length === 0
  )
    return null;

  return categoryQuestions[
    Math.floor(Math.random() * categoryQuestions.length)
  ];
};
