import React, { useEffect } from 'react';

export default function CategoriesPage({
  categories = [],
  onCategorySelected,
}) {
  useEffect(() => {}, []);

  return (
    <div className='container flex-center-col'>
      <p className='choose-category'>إختر فئة</p>
      <div className='cards-container flex-center'>
        {categories.map((cat) => (
          <button
            className='icon-button'
            key={cat.categoryName}
            onClick={() => onCategorySelected(cat)}
          >
            <img
              src={cat.imgUrl}
              className='category-icon'
              alt={cat.categoryName}
            />
          </button>
        ))}
      </div>
    </div>
  );
}
