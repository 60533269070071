import { useState, useEffect } from 'react';
import './styles/global.css';
import CategoriesPage from './pages/CategoriesPage';
import { getCategories, getRadnomCategoryQuestion } from './lib/dataProvider';
import QuestionPage from './pages/QuestionPage';
function App() {
  const [categories, setCategories] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const fetch = async () => {
    try {
      const res = await getCategories();
      setCategories(res);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  const onCategorySelected = async (category) => {
    try {
      const result = await getRadnomCategoryQuestion(category.id);
      if (!result) alert('no questions found');
      setSelectedQuestion(result);
    } catch (error) {}
  };

  return (
    <div>
      <header>{/* TODO darb logo goes here */}</header>

      {selectedQuestion ? (
        <QuestionPage
          onBack={() => setSelectedQuestion(null)}
          question={selectedQuestion}
        />
      ) : (
        <CategoriesPage
          onCategorySelected={onCategorySelected}
          categories={categories}
        />
      )}
    </div>
  );
}

export default App;
