import { initializeApp } from 'firebase/app';
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { enableIndexedDbPersistence } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCnMnAIykl5Nhqc6_Z2wKSh4LLkCzQ5uKE',
  authDomain: 'darb-21916.firebaseapp.com',
  projectId: 'darb-21916',
  storageBucket: 'darb-21916.appspot.com',
  messagingSenderId: '454226779969',
  appId: '1:454226779969:web:6e87935641e44a21958bc0',
  measurementId: 'G-866JGGDHBP',
};
const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);

enableIndexedDbPersistence(db).catch((err) => {
  if (err.code == 'failed-precondition') {
    console.log(
      'Multiple tabs open, persistence can only be enabled in one tab at a a time.'
    );
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code == 'unimplemented') {
    console.log('The current browser does not support all of the');
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
});
// Subsequent queries will use persistence, if it was enabled successfully
// these mehtods will be used by data layer

export const findAll = async (collectionName) => {
  // TODO method to find all documents in collection
  let docsArray = [];
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      docsArray.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return docsArray;
  } catch (e) {
    throw e;
  }
};

export const getWithFieldQuery = async (
  collectionName,
  qureyField,
  operator,
  queryForValue
) => {
  const collectionRef = collection(db, collectionName);
  const queryArray = [];
  const q = query(collectionRef, where(qureyField, operator, queryForValue));
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      queryArray.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return queryArray;
  } catch (e) {
    throw e;
  }
};
