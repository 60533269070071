import { useEffect, useState } from 'react';

const useCountdown = ({ durationInSeconds }) => {
  const [counter, setCounter] = useState(durationInSeconds);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  return { counter, setCounter };
};

export default useCountdown;
