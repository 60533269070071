import React, { useState } from 'react';
import useCountdown from '../hooks/useCountdown';

export default function QuestionPage({ question, onBack }) {
  const [answerIsVisible, setanswerIsVisible] = useState(false);
  const { counter } = useCountdown({ durationInSeconds: 30 });

  const toogleAnswerVisibility = () => {
    setanswerIsVisible(!answerIsVisible);
  };
  return (
    <div style={{ padding: 18 }}>
      <button className='icon-button' onClick={onBack}>
        <img width={40} height={40} src='/images/icons/back.png' />
      </button>
      <div className='flex-center-col '>
        {counter !== 0 ? (
          <p style={{ color: 'white', fontSize: 22 }}>
            {counter < 10 && '0'}
            {counter}
          </p>
        ) : (
          <p style={{ color: 'white', fontSize: 22 }}>
            انتهى الوقت - Time is over
          </p>
        )}
      </div>
      <div className='page container flex-col'>
        <section>
          <div className='questionContainer'>
            <p className='question' id='theQuestion'>
              {question.englishQuestion}
            </p>
            <p className='question' id='theQuestion'>
              {question.arabicQuestion}
            </p>
            <span className='visual'></span>
          </div>

          <div className='answers-container flex-center-col'>
            {answerIsVisible && (
              <div className='answer'>
                <p id='answer'>{question.englishAnswer}</p>
                <p id='answer'>{question.arabicAnswer}</p>
              </div>
            )}
            <button onClick={toogleAnswerVisibility} className='show-answer'>
              {answerIsVisible
                ? 'اخفاء الاجابة - Hide Answer'
                : ' أظهر الإجابة - Show Answer'}
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}
